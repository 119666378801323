import React from "react"
import Img from "gatsby-image"

import { sortByKeyValue } from "../functions/sort"

export default ({ photos }) => (
    <div
      className="uk-child-width-1-3 uk-child-width-1-4@s uk-child-width-1-3@m uk-child-width-1-4@l uk-child-width-1-5@xl uk-text-center uk-flex-center uk-grid-small"
      data-uk-grid
      data-uk-lightbox
    >
      {photos &&
        sortByKeyValue(photos, "ASC", "title").map(photo => (
          <div key={photo.contentful_id}>
            <div className="uk-transition-toggle uk-box-shadow-hover-large">
              <div className="uk-transition-scale-up uk-transition-opaque">
                <a href={photo.fluid.src} data-caption={photo.caption}>
                  <Img
                    fluid={{
                      ...photo.fluid,
                      aspectRatio: 1,
                    }}
                    data-uk-img
                    className="image-thumb uk-box-shadow-medium"
                  />
                </a>
              </div>
            </div>
          </div>
        ))}
    </div>
  )
