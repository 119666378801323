import React, { Component, Fragment } from "react"
import { graphql } from "gatsby"
import textversionjs from "textversionjs"

import Context from "../components/Context"
import Comments from "../components/Comments"
import SEO from "../components/Seo"
import Cover from "../components/Cover"
import Description from "../components/Description"
import Photos from "../components/Photos"

import Follow from "../components/Follow"
import Pagination from "../modules/Pagination"
import Anchor from "../modules/Anchor"
import ScrollDown from "../modules/ScrollDown"
import ScrollUp from "../modules/ScrollUp"

import { slugify } from "../functions/slugify"
import { getContentfulEntry } from "../functions/apiLambda"
import { filterCommentsByEntry } from "../functions/contentfulData"
import moment from "../functions/moment"

export default class Day extends Component {
  state = {
    sections: {
      cover: "couverture",
      description: "résumé",
      photos: "photos",
      comments: "commentaires",
    },
  }

  componentDidMount() {
    if (this.context.data.sections !== this.state.sections) {
      this.context.set({ sections: this.state.sections })
    }

    const { data } = this.props

    // current page
    const day = data.day
    const part = day.part[0]
    const event = day.part[0].event[0]

    let allPhotos = []
    day.photos &&
      day.photos.forEach(photo => {
        allPhotos = [
          ...allPhotos,
          { caption: day.name + " : " + day.title, ...photo },
        ]
      })
    if (this.state.allPhotos !== allPhotos) {
      this.setState({ allPhotos })
    }

    const header = {
      currentName: day.name,
      currentType: "day",
      partName: part.name,
      partLink: "/" + event.slug + "/" + part.slug,
      eventName: event.name,
      eventLink: "/" + event.slug,
      buttons: this.state.sections,
      counts: {
        photos: allPhotos ? allPhotos.length : null,
        comments: this.state.comments ? this.state.comments.length : null,
      },
    }
    if (this.context.data.header !== header) {
      this.context.set({ header })
    }

    const { mapdata } = day
    if (this.context.data.mapdata !== mapdata) {
      this.context.set({ mapdata })
    }
  }

  componentDidUpdate() {
    const { allComments } = this.context.data

    // console.log(
    //   "DAY ==> componentDidUpdate() ==> ",
    //   allComments,
    //   this.state.allComments
    // )

    if (allComments) {
      if (this.state.allComments !== allComments) {
        this.setState({ allComments })

        getContentfulEntry(this.props.data.day.contentful_id)
          // Filter current entry comments
          .then(entry => {
            return filterCommentsByEntry(allComments, entry)
          })
          // Add comments to state
          .then(matchComments => {
            // console.log("matchComments", matchComments)
            this.setState({ comments: matchComments })
            const commentsCount = matchComments.length
            if (this.context.data.header.counts.comments !== commentsCount) {
              let header = { ...this.context.data.header }
              header.counts.comments = commentsCount
              this.context.set({ header })
            }
          })
      }
    }
  }

  render() {
    const { sections, allPhotos } = this.state
    const { data } = this.props

    // current page
    const day = data.day
    const part = day.part[0]
    const event = day.part[0].event[0]
    // previous page
    const prevDay = data.prevDay
    const prevDayPart = prevDay && prevDay.part[0]
    const prevDayEvent = prevDayPart && prevDayPart.event[0]
    const prevUrl =
      prevDay &&
      "/" + prevDayEvent.slug + "/" + prevDayPart.slug + "/" + prevDay.slug
    // next page
    const nextDay = data.nextDay
    const nextDayPart = nextDay && nextDay.part[0]
    const nextDayEvent = nextDayPart && nextDayPart.event[0]
    const nextUrl =
      nextDay &&
      "/" + nextDayEvent.slug + "/" + nextDayPart.slug + "/" + nextDay.slug

    return (
      <Fragment>
        {/* SEO */}

        {day && (
          <SEO
            pageTitle={event && `${event.title} ∙ ${day.name} : ${day.title}`}
            pageDescription={
              (day.description &&
                textversionjs(day.description.childMarkdownRemark.html)) ||
              (part.description &&
                textversionjs(part.description.childMarkdownRemark.html)) ||
              (event.description &&
                textversionjs(event.description.childMarkdownRemark.html))
            }
            pageImage={day.cover && day.cover.fluid.src}
            pageUrl={
              this.context.data.metadata.siteUrl +
              "/" +
              event.slug +
              "/" +
              part.slug +
              "/" +
              day.slug
            }
          />
        )}

        {/* END : SEO */}

        {/* COVER */}

        <Anchor id={sections.cover} />

        <Cover
          current={day}
          prev={prevUrl}
          next={nextUrl}
          scroll={sections.description}
        />

        <Anchor id={"parallax-menu"} />

        {/* END : COVER */}

        {/* DESCRIPTION */}

        <Anchor id={sections.description} />

        <div className="uk-background-default">
          <div className="uk-section uk-position-relative">
            <div className="uk-position-top">
              <ScrollUp to={"#" + slugify(sections.cover)} />
            </div>

            <div className="uk-container uk-container-medium uk-animation-fade">
              <h3 className="uk-text-center uk-text-uppercase uk-margin-medium-bottom">
                {sections.description}
              </h3>

              <Description
                date={moment(day.date).format("D MMMM YYYY")}
                text={
                  day.description && day.description.childMarkdownRemark.html
                }
                youtube={day.youtube}
                thumb={day.thumb}
              />

              <div className="uk-text-center">
                <Follow
                  compact={false}
                  reference={event.contentful_id}
                  title={event.title}
                />
              </div>

              <Pagination
                prevSlug={
                  prevUrl && prevUrl + "#" + slugify(sections.description)
                }
                prevTitle={prevDay && prevDay.name + " : " + prevDay.title}
                nextSlug={
                  nextUrl && nextUrl + "#" + slugify(sections.description)
                }
                nextTitle={nextDay && nextDay.name + " : " + nextDay.title}
              />
            </div>

            <div className="uk-position-bottom uk-padding-small">
              <ScrollDown to={"#" + slugify(sections.photos)} />
            </div>
          </div>
        </div>

        {/* END : DESCRIPTION */}

        {/* PHOTOS */}

        <Anchor id={sections.photos} />

        <div className="uk-background-secondary uk-light">
          <div className="uk-section uk-position-relative">
            <div className="uk-position-top">
              <ScrollUp to={"#" + slugify(sections.description)} />
            </div>

            <div className="uk-container uk-container-medium">
              <h3 className="uk-text-center uk-text-uppercase uk-margin-medium-bottom">
                {sections.photos}
              </h3>

              <Photos photos={allPhotos} />

              <div className="uk-text-center">
                <Follow
                  compact={false}
                  reference={event.contentful_id}
                  title={event.title}
                />
              </div>

              <Pagination
                prevSlug={prevUrl && prevUrl + "#" + slugify(sections.photos)}
                prevTitle={prevDay && prevDay.name + " : " + prevDay.title}
                nextSlug={nextUrl && nextUrl + "#" + slugify(sections.photos)}
                nextTitle={nextDay && nextDay.name + " : " + nextDay.title}
              />
            </div>

            <div className="uk-position-bottom uk-padding-small">
              <ScrollDown to={"#" + slugify(sections.comments)} />
            </div>
          </div>
        </div>

        {/* END : PHOTOS */}

        {/* COMMENTS */}

        <Anchor id={sections.comments} />

        {/* <div className="uk-overlay uk-overlay-primary uk-light uk-padding-remove"> */}
        <div className="uk-background-muted">
          <div className="uk-section uk-position-relative">
            <div className="uk-position-top">
              <ScrollUp to={"#" + slugify(sections.photos)} />
            </div>

            <div className="uk-container uk-container-medium uk-animation-fade">
              <h3 className="uk-text-center uk-text-uppercase uk-margin-medium-bottom">
                {sections.comments}
              </h3>

              <Comments
                comments={this.state.comments}
                reference={day.contentful_id}
                // url={window.location.origin + window.location.pathname}
              />

              <div className="uk-text-center">
                <Follow
                  compact={false}
                  reference={event.contentful_id}
                  title={event.title}
                />
              </div>

              <Pagination
                prevSlug={prevUrl && prevUrl + "#" + slugify(sections.comments)}
                prevTitle={prevDay && prevDay.name + " : " + prevDay.title}
                nextSlug={nextUrl && nextUrl + "#" + slugify(sections.comments)}
                nextTitle={nextDay && nextDay.name + " : " + nextDay.title}
              />
            </div>
          </div>
        </div>

        {/* END : COMMENTS */}
      </Fragment>
    )
  }
}

Day.contextType = Context

export const query = graphql`
  query($dayId: String!, $prevDayId: String!, $nextDayId: String!) {
    day: contentfulDay(contentful_id: { eq: $dayId }) {
      contentful_id
      name
      title
      date
      slug
      cover {
        fluid(maxWidth: 1920) {
          ...GatsbyContentfulFluid
        }
      }
      description {
        childMarkdownRemark {
          html
        }
      }
      youtube
      thumb {
        fluid(maxWidth: 1920) {
          ...GatsbyContentfulFluid
        }
      }
      mapdata
      part {
        contentful_id
        name
        title
        slug
        description {
          childMarkdownRemark {
            html
          }
        }
        event {
          contentful_id
          name
          title
          slug
          description {
            childMarkdownRemark {
              html
            }
          }
          blog {
            contentful_id
          }
        }
      }
      photos {
        contentful_id
        title
        fluid(maxWidth: 1920) {
          ...GatsbyContentfulFluid
        }
      }
      trace {
        content
      }
    }
    prevDay: contentfulDay(contentful_id: { eq: $prevDayId }) {
      name
      title
      slug
      part {
        slug
        event {
          slug
        }
      }
    }
    nextDay: contentfulDay(contentful_id: { eq: $nextDayId }) {
      name
      title
      slug
      part {
        slug
        event {
          slug
        }
      }
    }
  }
`
